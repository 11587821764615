import { cn } from '@hapstack/common'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import type { ComponentProps } from 'react'

import type { IconName } from './Icon'
import { Icon } from './Icon'

type NotificationProps = {
  icon?: IconName
  emoji?: string
  children: React.ReactNode
}

const notificationStyles = cva(
  'w-fit rounded-lg font-normal flex items-center',
  {
    variants: {
      variant: {
        neutral: 'bg-secondary text-inherit',
        info: 'bg-sky-100 text-sky-700 border-sky-600',
        success: 'bg-success-100 text-success-900',
        error: 'bg-error-100 text-error-900',
        warning: 'bg-warning-100 text-warning-900',
      },
      size: {
        sm: 'text-sm px-2 py-1.5 gap-2',
        md: 'text-sm px-2.5 py-3 gap-2',
      },
    },
    defaultVariants: {
      variant: 'info',
      size: 'md',
    },
  }
)

type NotificationStyles = VariantProps<typeof notificationStyles> &
  ComponentProps<'div'>

const Icons: Record<NonNullable<NotificationStyles['variant']>, IconName> = {
  info: 'lightbulb',
  success: 'party-popper',
  error: 'shield-alert',
  warning: 'shield-alert',
  neutral: 'lightbulb',
} as const

export const Notification = ({
  variant = 'info',
  size,
  icon,
  emoji,
  children,
  className,
  ...props
}: NotificationProps & NotificationStyles) => {
  if (!variant) throw new Error('Notification variant is required')

  return (
    <div
      className={cn(notificationStyles({ variant, size }), className)}
      {...props}
    >
      {emoji ? (
        <div
          className={cn(size === 'sm' ? 'text-lg' : 'text-xl', 'shrink-0')}
          role="img"
          aria-label="emoji"
        >
          {emoji}
        </div>
      ) : (
        <Icon
          name={icon || Icons[variant]}
          size={size === 'sm' ? 'md' : 'lg'}
          className="shrink-0"
        />
      )}

      {children}
    </div>
  )
}
