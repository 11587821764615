import { cn } from '@hapstack/common'
import { Button } from '@hapstack/ui'
import { Form, useNavigation } from '@remix-run/react'
import type { ComponentPropsWithRef } from 'react'

import type { providerSchema } from '~/services/auth/auth.server'

type Props = {
  provider:
    | typeof providerSchema.Enum.google
    | typeof providerSchema.Enum.microsoft
} & Omit<ComponentPropsWithRef<'button'>, 'color'>

export const LoginButton = ({ provider, className, ...props }: Props) => {
  const navigation = useNavigation()
  const isLoading =
    navigation.state !== 'idle' &&
    navigation.formData?.get('provider') === provider

  return (
    <Form
      method="POST"
      action="/login"
    >
      <input
        type="hidden"
        name="provider"
        value={provider}
      />
      <Button
        className={cn(
          'h-16 w-full bg-none shadow-sm',
          { 'bg-indigo-500 hover:bg-indigo-600': provider === 'google' },
          { 'bg-slate-600 hover:bg-slate-700': provider === 'microsoft' },
          className
        )}
        type="submit"
        loading={isLoading}
        icon={provider}
        {...props}
      >
        <span>
          Continue with <span className="capitalize">{provider}</span>
        </span>
      </Button>
    </Form>
  )
}
